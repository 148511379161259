<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio movimento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="movimento">
                    <div class="field">
                        <div class="title">Data movimento</div>
                        <div class="value">
                            {{ dateFormatWithMinutes(movimento.movimenti_data_registrazione) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Magazzino</div>
                        <div class="value">
                            {{ movimento.magazzini_titolo }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Tipo movimento</div>
                        <div class="value">
                            {{ movimento.movimenti_tipo_movimento_value }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Causale</div>
                        <div class="value">
                            {{ movimento.movimenti_causali_nome }}
                        </div>
                    </div>
                </div>

                <!-- Elenco articoli -->
                <div class="articoli_title">Articoli movimentati</div>
                <div v-if="movimento.movimenti_articoli.length === 0" class="no_articoli_container">
                    <div class="no_articoli">Nessun articolo da visualizzare</div>
                </div>
                <div v-else class="movimento">
                    <div v-for="(articolo, index) in movimento.movimenti_articoli" :key="index" class="field">
                        <div class="value value_articoli">
                            <span>{{ articolo.movimenti_articoli_name }}</span>
                            <span>{{ articolo.movimenti_articoli_quantita }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent } from "vue";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";

export default defineComponent({
    name: "MovimentoDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        //console.log(props.data);
        const movimento = { ...props.data };
        //console.log(movimento);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        return {
            dateFormat,
            dateFormatWithMinutes,
            movimento,
            arrowBackOutline,
            closeModal,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new movimento btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.movimento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.movimento .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #7676803d;
}

.movimento .field:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
}
.movimento .field_motivazione {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.field .title,
.field_motivazione .title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.field_motivazione .title {
    margin-bottom: 8px;
}
.field .value,
.field_motivazione .value {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1b;
}
.field .value.value_articoli {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.value .badge_success {
    background-color: #22c55e;
}
.value .badge_warning {
    background-color: #f97316;
}
.value .badge_danger {
    background-color: #ef4444;
}

ion-button {
    --color: #ffffff;
}

.articoli_title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 12px;
}
.no_articoli_container {
    width: 100%;
    padding-top: 8px;
}
.no_articoli {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}
</style>
